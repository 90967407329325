export const urlZendesk = `${process.env.GATSBY_ZENDESK_BASE_URL}/api/v2/requests`

export const createReferralPartnerUrl = `${process.env.GATSBY_ALTEOS_API_BASE_URL}/v1/referral-partners`

export const headers = {
  'x-app-key': process.env.GATSBY_ALTEOS_APP_KEY,
}

export const BRILLENSCHUTZ_PREMIUMSCHUTZ_ID = 'b40e05f2-ed61-4a63-be03-c2aabe25e4c2'
export const BRILLENSCHUTZ_WEITSICHT_PLUS_ID = '1961d78e-63e2-4069-9053-21e7b4f5414b'

export const NON_BRILLEN_PRODUCT_SELECTED = 'NON_BRILLEN_PRODUCT_SELECTED'
export const BRILLENSCHUTZ_PREMIUMSCHUTZ_SELECTED = 'BRILLENSCHUTZ_PREMIUMSCHUTZ_SELECTED'
export const BRILLENSCHUTZ_WEITSICHT_PLUS_SELECTED = 'BRILLENSCHUTZ_WEITSICHT_PLUS_SELECTED'
export const ALL_BRILLEN_PRODUCT_SELECTED = 'ALL_BRILLEN_PRODUCT_SELECTED'
