import cx from 'classnames'
import { Field, FormikErrors, FormikProps, FormikTouched } from 'formik'
import get from 'lodash/get'
import React from 'react'

import { FormElement, Divider, InputType, IFormValue } from '@alteos/ui'

import {
  IDisclosureContract,
  IDisclosureLegalDocuments,
  IDisclosurePremiumschutz,
  IDisclosurePremiumschutzWeitsichtPlus,
  IDisclosureWeitsichtPlus,
} from '../../../../types/registerForm'
import handleFormikFieldChange from '../../../../utils/handleFormikFieldChange'
import {
  ALL_BRILLEN_PRODUCT_SELECTED,
  BRILLENSCHUTZ_PREMIUMSCHUTZ_SELECTED,
  BRILLENSCHUTZ_WEITSICHT_PLUS_SELECTED,
  NON_BRILLEN_PRODUCT_SELECTED,
} from '../../constants'
import { IGetOnSubmitFormFnValues } from '../../registerForm'

import * as styles from './disclosureContent.module.scss'

interface IDisclosureContent {
  disclosureContract: IDisclosureContract
  disclosureWeitsichtPlus: IDisclosureWeitsichtPlus
  disclosurePremiumschutz: IDisclosurePremiumschutz
  disclosurePremiumschutzWeitsichtPlus: IDisclosurePremiumschutzWeitsichtPlus
  disclosureLegalDocuments: IDisclosureLegalDocuments
  errors: FormikErrors<IGetOnSubmitFormFnValues>
  touched: FormikTouched<IGetOnSubmitFormFnValues>
  productSelectionContracts: { [key: string]: boolean }
}

const DisclosureContent = ({
  errors = {},
  touched,
  disclosureContract = null,
  disclosureWeitsichtPlus = null,
  disclosurePremiumschutz = null,
  disclosurePremiumschutzWeitsichtPlus = null,
  disclosureLegalDocuments = null,
  productSelectionContracts,
  ...input
}: IDisclosureContent & FormikProps<IGetOnSubmitFormFnValues>): JSX.Element => {
  const handleChange = (
    option: string | { target: { name: string; value: IFormValue } },
    fieldName: string
  ) => {
    const { setFieldTouched, setFieldValue } = input

    handleFormikFieldChange(option, fieldName, setFieldValue, setFieldTouched)
  }
  return (
    <div className={styles.container}>
      <Divider className={styles.divider} />

      <div className={cx(styles.subsection, styles.disclosure)}>
        {productSelectionContracts[NON_BRILLEN_PRODUCT_SELECTED] !== true ? null : (
          <Field name="disclosureContract">
            {({ field }) => (
              <FormElement
                type={InputType.checkbox}
                name="disclosureContract"
                error={get(errors, 'disclosureContract')}
                touched={get(touched, 'disclosureContract')}
                className={cx(styles.full, styles.checkboxContainer)}
                content={disclosureContract.disclosureContract}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        )}
        {productSelectionContracts[BRILLENSCHUTZ_WEITSICHT_PLUS_SELECTED] !== true ? null : (
          <Field name="disclosureWeitsichtPlus">
            {({ field }) => (
              <FormElement
                type={InputType.checkbox}
                name="disclosureWeitsichtPlus"
                error={get(errors, 'disclosureWeitsichtPlus')}
                touched={get(touched, 'disclosureWeitsichtPlus')}
                className={cx(styles.full, styles.checkboxContainer)}
                content={disclosureWeitsichtPlus.disclosureWeitsichtPlus}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        )}
        {productSelectionContracts[BRILLENSCHUTZ_PREMIUMSCHUTZ_SELECTED] !== true ? null : (
          <Field name="disclosurePremiumschutz">
            {({ field }) => (
              <FormElement
                type={InputType.checkbox}
                name="disclosurePremiumschutz"
                error={get(errors, 'disclosurePremiumschutz')}
                touched={get(touched, 'disclosurePremiumschutz')}
                className={cx(styles.full, styles.checkboxContainer)}
                content={disclosurePremiumschutz.disclosurePremiumschutz}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        )}
        {productSelectionContracts[ALL_BRILLEN_PRODUCT_SELECTED] !== true ? null : (
          <Field name="disclosurePremiumschutzWeitsichtPlus">
            {({ field }) => (
              <FormElement
                type={InputType.checkbox}
                name="disclosurePremiumschutzWeitsichtPlus"
                error={get(errors, 'disclosurePremiumschutzWeitsichtPlus')}
                touched={get(touched, 'disclosurePremiumschutzWeitsichtPlus')}
                className={cx(styles.full, styles.checkboxContainer)}
                content={disclosurePremiumschutzWeitsichtPlus.disclosurePremiumschutzWeitsichtPlus}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        )}
        <Field name="disclosureLegalDocuments">
          {({ field }) => (
            <FormElement
              type={InputType.checkbox}
              name="disclosureLegalDocuments"
              error={get(errors, 'disclosureLegalDocuments')}
              touched={get(touched, 'disclosureLegalDocuments')}
              className={cx(styles.full, styles.checkboxContainer)}
              content={disclosureLegalDocuments.disclosureLegalDocuments}
              value={field.value}
              tabIndex={0}
              onChange={handleChange}
            />
          )}
        </Field>
      </div>

      <Divider className={styles.divider} />
    </div>
  )
}
export default DisclosureContent
