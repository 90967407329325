import { Location } from '@reach/router'
import queryString from 'query-string'
import React from 'react'

const withLocation =
  <T extends object>(ComponentToWrap: React.ComponentType<T>) =>
  (props: T): JSX.Element =>
    (
      <Location>
        {({ location, navigate }) => (
          <ComponentToWrap
            {...props}
            location={location}
            navigate={navigate}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        )}
      </Location>
    )

export default withLocation
