import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

axios.defaults.headers.common['x-app-key'] = process.env.GATSBY_ALTEOS_APP_KEY

export type ApiResponse = Promise<AxiosResponse<any>>

export const get = (url: string, config: AxiosRequestConfig = {}): ApiResponse =>
  axios.get(url, config)

export const post = (
  url: string,
  data: Record<string, any>,
  config: AxiosRequestConfig = {}
): ApiResponse => axios.post(url, data, config)
