/* eslint-disable no-magic-numbers */
import { Formik, Form } from 'formik'
import React, { useState } from 'react'

import { SectionContainer } from '@alteos/ui'

import withLocation from '../../components/Router/withLocation'
import ButtonCta from './Elements/ButtonCta'
import CompanyData from './Elements/CompanyData'
import DisclosureContent from './Elements/DisclosureContent'
import MoreInformation from './Elements/MoreInformation'
import SourceContent from './Elements/SourceContent'
import { IGetOnSubmitFormFnValues, IInitialValues, IRegisterForm } from './registerForm'
import { getOnSubmitFormFn } from './utils/getOnSubmitForm'
import {
  getProductConfigExtension,
  getProductSelectionContracts,
  getSelectedProducts,
} from './utils/registerForm'
import { productConfigExtensionValidator, validationSchema } from './utils/validationSchema'

import * as styles from './registerForm.module.scss'

const RegisterForm = ({
  legalContent,
  moreInformation,
  sourceContent,
  disclosureContent,
  buttonLabel,
  successUrl,
  failureUrl,
  search,
  parsedQuery,
  country,
}: IRegisterForm): JSX.Element => {
  // To make the formik schema dynamic, we need to pass some values depending on the product selection
  const [formikSchemaOptions, setFormikSchemaOptions] = useState<IGetOnSubmitFormFnValues | object>(
    {}
  )
  const [focusedField, setFocusedField] = useState('')

  const {
    firmName,
    legalRepFirstName,
    legalRepLastName,
    legalEntity,
    salesTaxIdNumber,
    issuingAuthority,
    billingEmail,
    billingIban,
    firmRegistrationNumber,
    firmAddressCity,
    firmAddressHouse,
    firmAddressPlz,
    firmAddressStreet,
    firmTelephoneNumber,
    firmEmailId,
    firmEmailIdRepeat,
    productSelection,
    desiredURL,
    source,
    sourceReferral,
  } = search

  const initialValues: IInitialValues = {
    firmName: firmName || '',
    legalRepFirstName: legalRepFirstName || '',
    legalRepLastName: legalRepLastName || '',

    legalEntity: legalEntity || '',
    salesTaxIdNumber: salesTaxIdNumber || '',
    issuingAuthority: issuingAuthority || '',
    firmRegistrationNumber: firmRegistrationNumber || '',

    billingEmail: billingEmail || '',
    billingIban: billingIban || '',

    firmAddressCity: firmAddressCity || '',
    firmAddressHouse: firmAddressHouse || '',
    firmAddressPlz: firmAddressPlz || '',
    firmAddressStreet: firmAddressStreet || '',

    firmTelephoneNumber: firmTelephoneNumber || '',

    firmEmailId: firmEmailId || '',
    firmEmailIdRepeat: firmEmailIdRepeat || '',

    productSelection: getSelectedProducts(
      moreInformation.productSelectionOptions,
      productSelection
    ),
    desiredURL: desiredURL || '',
    source: source || parsedQuery.source || '',
    sourceReferral: sourceReferral || '',
    disclosureLegalDocuments: false,
    disclosureContract: false,
    disclosureWeitsichtPlus: false,
    disclosurePremiumschutz: false,
    disclosurePremiumschutzWeitsichtPlus: false,
    productConfigExtension: getProductConfigExtension(
      getSelectedProducts(moreInformation.productSelectionOptions, productSelection)
    ),
  }

  return (
    <SectionContainer noPaddingBottom noPaddingTop dataTest="more-information-section">
      <div className={styles.container}>
        <div className={styles.content}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema(
              country,
              productConfigExtensionValidator(moreInformation.productSelectionOptions),
              formikSchemaOptions,
              focusedField
            )}
            onSubmit={getOnSubmitFormFn(
              country,
              successUrl,
              failureUrl,
              moreInformation.productSelectionOptions
            )}
          >
            {(formikProps) => {
              return (
                <Form>
                  <MoreInformation
                    updateFormikSchemaOptions={setFormikSchemaOptions}
                    setFocusedField={setFocusedField}
                    {...moreInformation}
                    {...formikProps}
                    mainTitle={'Welche Alteos Versicherung(en) möchten Sie empfehlen?'}
                  />
                  <CompanyData
                    {...legalContent}
                    {...formikProps}
                    setFocusedField={setFocusedField}
                  />
                  <SourceContent
                    sourceList={undefined}
                    sourceSelection={undefined}
                    sourceReferral={undefined}
                    mainTitle={undefined}
                    {...sourceContent}
                    {...formikProps}
                    setFocusedField={setFocusedField}
                  />
                  <DisclosureContent
                    {...disclosureContent}
                    {...formikProps}
                    productSelectionContracts={getProductSelectionContracts(
                      formikProps.values.productSelection
                    )}
                  />
                  <ButtonCta label={buttonLabel} handleSubmit={formikProps.handleSubmit} />
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </SectionContainer>
  )
}

export default withLocation(RegisterForm)
