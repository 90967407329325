import React from 'react'

import { Button } from '@alteos/ui'

import * as styles from './buttonCta.module.scss'

interface IButtonCta {
  label: string
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
}

const ButtonCta = ({ label, handleSubmit }: IButtonCta): JSX.Element => (
  <Button
    rounded
    color="secondary"
    icon="arrow-right"
    className={styles.button}
    dataTest="register-submit-button"
    tabIndex={0}
    handleClick={handleSubmit as unknown as (event: React.MouseEvent<unknown, MouseEvent>) => void}
  >
    {label}
  </Button>
)

ButtonCta.defaultProps = {
  label: null,
}

export default ButtonCta
