import { CountryCodes } from '../dictionaries'

export interface IPartnerObjectValues {
  values?: { sourceReferral: string }
  companyName: string
  legalRepFirstName: string
  legalRepLastName: string
  legalEntity: string
  salesTaxIdNumber: string
  companyAddressCity: string
  companyAddressHouseNumber: string
  companyAddressPlz: string
  companyAddressStreet: string
  companyEmail: string
  productSelectionIds: string
  productConfigExtension: IProductConfigExtension
  subdomain: string
  source: string
  sourceReferral: string
  companyRegistrationNumber?: string
  companyPhone?: string
  issuingAuthority?: string
  iban?: string
  billingEmail?: string
  country: CountryCodes
}
export interface IProductConfigExtension {
  [id: string]: IValuesProductConfigExtension
}
export interface IValuesProductConfigExtension {
  values: IValues
}
export interface IValues {
  [id: string]: number | string | boolean
}

export const trimObjValues = (obj: IPartnerObjectValues): IPartnerObjectValues => {
  const modifiedObj = { ...obj }
  const objKeys = Object.keys(obj)

  if (objKeys.length === 0) return modifiedObj

  objKeys.forEach((key) => {
    const val = modifiedObj[key]
    if (val instanceof Object) {
      modifiedObj[key] = trimObjValues(val)
    }
    if (typeof val === 'string') {
      modifiedObj[key] = val.trim()
    }
  })
  return modifiedObj
}
