import { IProductSelectionOptionsEntity } from '../../../types/registerForm'
import { IProductConfigExtension } from '../../../utils/trim'
import {
  ALL_BRILLEN_PRODUCT_SELECTED,
  BRILLENSCHUTZ_PREMIUMSCHUTZ_ID,
  BRILLENSCHUTZ_PREMIUMSCHUTZ_SELECTED,
  BRILLENSCHUTZ_WEITSICHT_PLUS_ID,
  BRILLENSCHUTZ_WEITSICHT_PLUS_SELECTED,
  NON_BRILLEN_PRODUCT_SELECTED,
} from '../constants'

export const getSelectedProducts = (
  productSelectionOptions: IProductSelectionOptionsEntity[],
  productSelection: string
): { [key: string]: boolean } =>
  productSelectionOptions.reduce((acc, item) => {
    acc[item.key] = false
    if (productSelection && productSelection.split(',').includes(item.key)) {
      acc[item.key] = true
    }
    return acc
  }, {})

export const getProductConfigExtension = (selectedProducts: {
  [key: string]: boolean
}): IProductConfigExtension =>
  Object.keys(selectedProducts).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        values: {},
      },
    }),
    {}
  )

export const getProductSelectionContracts = (
  selectedProducts: {
    [key: string]: boolean
  } = {}
): { [key: string]: boolean } => {
  const result = {
    [NON_BRILLEN_PRODUCT_SELECTED]: false,
    [BRILLENSCHUTZ_PREMIUMSCHUTZ_SELECTED]:
      selectedProducts?.[BRILLENSCHUTZ_PREMIUMSCHUTZ_ID] === true,
    [BRILLENSCHUTZ_WEITSICHT_PLUS_SELECTED]:
      selectedProducts?.[BRILLENSCHUTZ_WEITSICHT_PLUS_ID] === true,
    [ALL_BRILLEN_PRODUCT_SELECTED]:
      selectedProducts?.[BRILLENSCHUTZ_PREMIUMSCHUTZ_ID] === true &&
      selectedProducts?.[BRILLENSCHUTZ_WEITSICHT_PLUS_ID] === true,
  }

  if (result[ALL_BRILLEN_PRODUCT_SELECTED]) {
    result[BRILLENSCHUTZ_PREMIUMSCHUTZ_SELECTED] = false
    result[BRILLENSCHUTZ_WEITSICHT_PLUS_SELECTED] = false
  }

  for (const productId in selectedProducts) {
    if (selectedProducts[productId] === true) {
      if (
        productId !== BRILLENSCHUTZ_PREMIUMSCHUTZ_ID &&
        productId !== BRILLENSCHUTZ_WEITSICHT_PLUS_ID
      ) {
        result[NON_BRILLEN_PRODUCT_SELECTED] = true
        break
      }
    }
  }
  return result
}
