import { datadogRum } from '@datadog/browser-rum'
import { captureException, withScope } from '@sentry/browser'
import { graphql, navigate } from 'gatsby'
import isNil from 'lodash/isNil'
import queryString from 'query-string'
import React, { Dispatch, useEffect, useState } from 'react'

import { SupportHeader, Footer } from '@alteos/ui'

import { getProductsConfiguration } from '../api/partner.service'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { CountryCodes, countryRoutes } from '../dictionaries'
import RegisterForm from '../sections/RegisterForm'
import { IRegisterForm } from '../sections/RegisterForm/registerForm'
import { generateProducts } from '../sections/RegisterForm/utils/generateProducts'
import { IReferralPartnerProduct } from '../types/products'
import { ILocation, IRegistrierenPageData } from '../types/registerForm'
import { getCtaParams } from '../utils/contentMaps/ctaContent'
import socialLinks from '../utils/socialLinks'
/* eslint-disable no-magic-numbers */

interface IRegisterPage {
  data: {
    contentfulPartnerRegistrationPageContent: IRegistrierenPageData
  }
  location: ILocation
}

const mapTooltipContent = <T,>(config: T): T => {
  const result: T = {} as T
  Object.keys(config).forEach((key) => {
    if (typeof config[key] !== 'object') {
      result[key] = config[key]
      return
    }

    result[key] = { ...config[key] }

    const fieldToolTip = config[key]?.fieldToolTip

    if (!isNil(fieldToolTip)) {
      const tooltipContent = fieldToolTip.fieldToolTip
      result[key].fieldToolTip = tooltipContent
    } else {
      result[key].fieldToolTip = null
    }
  })
  return result
}

const mapContent = (
  data: IRegistrierenPageData,
  setPopupState: Dispatch<React.SetStateAction<boolean>>
) => {
  const header = {
    items: data.headerContent.content.map((item) => ({
      to: item.ctaTo,
      label: item.ctaLabel,
    })),
  }

  const hero = {
    title: data.heroTitle.heroTitle,
  }

  const footer = {
    copyright: data.footerContent.copyright.copyright,
    items: data.footerContent.content.map((item) => getCtaParams(item, setPopupState)),
    socialLinks,
  }

  const legalEntityList = data.firmLegalContent.legalEntityList.map((item) => ({
    value: item,
    key: item,
  }))

  const sourceList = data.sourceContent.sourceList.map((item) => ({
    key: item
      .replace(/-/g, '_')
      .replace(/ /g, '_')
      .toLowerCase()
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/ü/g, 'ue')
      .replace(/ß/g, 'ss'),
    value: item,
  }))

  const mappedFirmLegalContent = mapTooltipContent(data.firmLegalContent)
  const { firmAddressTitleToolTip } = data.firmLegalContent
  if (firmAddressTitleToolTip !== null) {
    mappedFirmLegalContent.firmAddressTitleToolTip = firmAddressTitleToolTip.firmAddressTitleToolTip
  }

  const mappedMoreInformation = mapTooltipContent(data.moreInformation)
  const mappedSourceContent = mapTooltipContent(data.sourceContent)
  const mappedDisclosureContent = mapTooltipContent(data.disclosureContent)

  const formContent = {
    legalContent: { ...mappedFirmLegalContent, legalEntityList },
    moreInformation: { ...mappedMoreInformation },
    sourceContent: { ...mappedSourceContent, sourceList },
    disclosureContent: { ...mappedDisclosureContent },
    buttonLabel: data.registrationCompleteCtaLabel,
    successUrl: data.registrationSuccessRedirect,
    failureUrl: data.registrationFailureRedirect,
  }

  return {
    header,
    hero,
    footer,
    formContent,
  }
}

const RegisterFormPage = ({ data, location }: IRegisterPage): JSX.Element => {
  const [source, setSource] = useState(undefined)
  const [productsConfiguration, setProductsConfiguration] = useState<IReferralPartnerProduct[]>([])

  useEffect(() => {
    setSource(sessionStorage.getItem('source') || location.search)
  }, [location.search])

  useEffect(() => {
    async function fetchData() {
      const response = await getProductsConfiguration()
      setProductsConfiguration(response as unknown as IReferralPartnerProduct[])
    }
    try {
      void fetchData()
    } catch (e) {
      withScope((scope) => {
        if (typeof window !== 'undefined') {
          scope.setExtras({ url: window.location.href })
        }
        captureException(`Cannot load products: "${location.pathname}"`)
      })
      datadogRum.addError(e)

      void navigate(data.contentfulPartnerRegistrationPageContent.registrationFailureRedirect)
    }
  }, [data.contentfulPartnerRegistrationPageContent.registrationFailureRedirect, location.pathname])

  if (!productsConfiguration.length) {
    return null
  }

  return (
    <Layout>
      {({ setPopupState }) => {
        const parsedQuery = typeof source !== 'undefined' ? queryString.parse(source) : {}
        const content = mapContent(data.contentfulPartnerRegistrationPageContent, setPopupState)
        const country: CountryCodes = countryRoutes[location.pathname]

        if (!country) {
          withScope((scope) => {
            if (typeof window !== 'undefined') {
              scope.setExtras({ url: window.location.href })
            }
            captureException(`Country was not found for the path: "${location.pathname}"`)
          })
          datadogRum.addError(`Country was not found for the path: "${location.pathname}"`)

          void navigate(content.formContent.failureUrl)
          return null
        }

        return (
          <>
            <SupportHeader {...content.hero} headerContent={content.header} />
            <RegisterForm
              {...({
                parsedQuery,
                country,
                ...{
                  ...content.formContent,
                  moreInformation: {
                    ...content.formContent.moreInformation,
                    productSelectionOptions: generateProducts(productsConfiguration, country),
                  },
                },
              } as unknown as IRegisterForm)}
            />
            <Footer dataTest="Footer" {...content.footer} />
            <Seo noIndex={false} />
          </>
        )
      }}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPartnerRegistrationPageContent(slug: { eq: $slug }) {
      headerContent {
        content {
          ctaLabel
          ctaTo
        }
      }
      heroTitle {
        heroTitle
      }
      footerContent {
        copyright {
          copyright
        }
        content {
          ctaLabel
          ctaTo
        }
      }
      firmLegalContent {
        billingTitle
        billingIban {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        billingEmail {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        firmAddressCity {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        firmAddressHouse {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        firmAddressPlz {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        firmAddressStreet {
          fieldTitle
          fieldNote
          fieldPlaceholderContent
          fieldToolTip {
            fieldToolTip
          }
        }
        firmEmailId {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        firmEmailIdRepeat {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        firmName {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        firmRegistrationNumber {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        firmTelephoneNumber {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        issuingAuthority {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        legalEntity {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        legalEntityList
        legalRepFirstName {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        legalRepLastName {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        salesTaxIdNumber {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        mainTitle
        firmAddressTitle
        firmAddressTitleToolTip {
          firmAddressTitleToolTip
        }
      }
      moreInformation {
        desiredURL {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        productSelection {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        productSelectionOptions {
          key
          value
        }
        mainTitle
      }
      sourceContent {
        mainTitle
        sourceList
        sourceSelection {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
        sourceReferral {
          fieldPlaceholderContent
          fieldTitle
          fieldNote
          fieldToolTip {
            fieldToolTip
          }
        }
      }
      disclosureContent {
        disclosureContract {
          disclosureContract
        }
        disclosureLegalDocuments {
          disclosureLegalDocuments
        }
        disclosureWeitsichtPlus {
          disclosureWeitsichtPlus
        }
        disclosurePremiumschutz {
          disclosurePremiumschutz
        }
        disclosurePremiumschutzWeitsichtPlus {
          disclosurePremiumschutzWeitsichtPlus
        }
      }
      registrationCompleteCtaLabel
      registrationSuccessRedirect
      registrationFailureRedirect
    }
  }
`

export default RegisterFormPage
