import { IPartnerObjectValues } from '../../../utils/trim'
import { IMapToZendeskPayloadResult } from '../registerForm'

export default function mapToZendeskPayload(
  values: IPartnerObjectValues
): IMapToZendeskPayloadResult {
  // serializing productConfigExtension object to string to properly display object
  // in the zendesk ticket
  const shopPartnerPayload = {
    ...values,
    productConfigExtension: JSON.stringify(values.productConfigExtension),
  }

  return {
    request: {
      subject: 'Shop-partner register partner request',
      comment: {
        body: Object.entries(shopPartnerPayload)
          .map(([key, value]) => `${key} : ${value}`)
          .join('\n\n'),
        uploads: [],
      },
      requester: {
        name: values.companyName,
        email: values.companyEmail,
      },
      ticket_form_id: null,
      fields: {},
    },
  }
}
