import sortBy from 'lodash/sortBy'

import { InputType } from '@alteos/ui'

import { CountryCodes } from '../../../dictionaries'
import { IExtension, IReferralPartnerProduct, UsedBy } from '../../../types/products'
import { IProductSelectionOptionsEntity } from '../../../types/registerForm'

export const generateProducts = (
  products: IReferralPartnerProduct[],
  country = CountryCodes.DE
): IProductSelectionOptionsEntity[] => {
  const currentLocale = CountryCodes.DE.toLowerCase()

  const productData = products.reduce((productAcc, currProduct) => {
    const isSelectedCountry = currProduct.country?.includes(country as CountryCodes) === true
    const isExternalAllowed = currProduct.isUsedBy?.includes(UsedBy.Partner) === true

    if (isSelectedCountry && isExternalAllowed) {
      const configOption = currProduct.extensions.reduce((extensionAcc, currExtension) => {
        const isAllowedExtension = currExtension.isUsedBy?.includes(UsedBy.Partner) === true

        if (isAllowedExtension) {
          return [
            ...extensionAcc,
            {
              id: currExtension.name,
              name: currExtension.name,
              type: InputType.select,
              required: currExtension.required,
              label: currExtension.label[currentLocale],
              options: currExtension.values.map((option, i) => ({
                key: option.key,
                value: option.label[currentLocale],
              })),
            },
          ] as unknown as IExtension[]
        }
        return extensionAcc
      }, [] as IExtension[])

      const hasConfigOption = configOption.length > 0
      return [
        ...productAcc,
        {
          key: currProduct.id,
          value: currProduct.displayName.partner[currentLocale],
          hasConfigOption,
          ...(hasConfigOption ? { configOption } : {}),
        },
      ]
    }
    return productAcc
  }, [])

  return sortBy(productData, 'value')
}
