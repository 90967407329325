import { AxiosRequestConfig } from 'axios'

import { IReferralPartnerProduct } from '../types/products'
import {
  checkSubDomainAvailabilityUrl,
  createReferralPartnerUrl,
  getProductsConfigurationUrl,
} from './urls'

import { post, ApiResponse, get } from './index'

export const createReferralPartner = (
  data: Record<string, any>,
  host?: string,
  config?: AxiosRequestConfig
): ApiResponse => post(createReferralPartnerUrl(host), data, config)

export const checkSubDomainAvailability = (
  subdomain: string,
  host?: string,
  config?: AxiosRequestConfig
): ApiResponse =>
  post(
    checkSubDomainAvailabilityUrl(host),
    {
      subdomain,
    },
    config
  )

export const getProductsConfiguration = async (host?: string): Promise<IReferralPartnerProduct[]> =>
  (await get(getProductsConfigurationUrl(host))).data
