import cx from 'classnames'
import { Field, FormikProps } from 'formik'
import get from 'lodash/get'
import React from 'react'

import { FormElement, Divider, TooltipPopup, IFormValue } from '@alteos/ui'

import { IRegisterField } from '../../../../types/registerForm'
import handleFormikFieldChange from '../../../../utils/handleFormikFieldChange'
import { IGetOnSubmitFormFnValues } from '../../registerForm'

import * as styles from './companyData.module.scss'

export interface ICompanyData {
  mainTitle: string
  firmAddressTitle: string
  firmAddressTitleToolTip: string
  billingTitle: string
  billingTitleToolTip: string
  values: IGetOnSubmitFormFnValues
  firmEmailId: IRegisterField | null | string
  firmEmailIdRepeat: IRegisterField
  setFocusedField: (field: string) => void
}
export interface IRestValues extends FormikProps<IGetOnSubmitFormFnValues> {
  legalRepFirstName: IRegisterField
  firmName: IRegisterField
  legalRepLastName: IRegisterField
  salesTaxIdNumber: IRegisterField
  legalEntityList: {
    value: string
    key: string
  }[]
  legalEntity: IRegisterField
  issuingAuthority: IRegisterField
  firmRegistrationNumber: IRegisterField
  billingIban: IRegisterField
  firmAddressStreet: IRegisterField
  firmAddressHouse: IRegisterField
  firmAddressPlz: IRegisterField
  firmAddressCity: IRegisterField
  firmTelephoneNumber: IRegisterField
  billingEmail: IRegisterField
}

const preventDefaultAction = (event: { preventDefault: () => void }) => {
  event.preventDefault()
  return false
}

const CompanyData = ({
  setFocusedField,
  billingTitle,
  billingTitleToolTip,
  values,
  firmEmailId,
  firmEmailIdRepeat,
  errors = {},
  touched,
  mainTitle = '',
  firmAddressTitle = '',
  firmAddressTitleToolTip = '',
  ...input
}: Partial<ICompanyData & IRestValues>): JSX.Element => {
  const emailHasError = get(errors, 'firmEmailIdRepeat')

  const emailConfirmed = !emailHasError && firmEmailId !== '' && firmEmailId === firmEmailIdRepeat

  const handleChange = (
    option: string | { target: { name: string; value: IFormValue } },
    fieldName: string
  ) => {
    const { setFieldTouched, setFieldValue } = input
    setFocusedField(option?.target?.name)
    handleFormikFieldChange(option, fieldName, setFieldValue, setFieldTouched)
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>
        <h1 className={styles.h2}>{mainTitle}</h1>
      </span>

      <div className={styles.subsection}>
        <Field name="firmName">
          {({ field }) => (
            <FormElement
              type="string"
              name="firmName"
              error={get(errors, 'firmName')}
              touched={get(touched, 'firmName')}
              tooltipPopup={input.firmName.fieldToolTip}
              label={input.firmName.fieldTitle}
              placeholder={input.firmName.fieldPlaceholderContent}
              note={input.firmName.fieldNote}
              className={cx(styles.inputEl, styles.large, styles.t75)}
              value={field.value}
              tabIndex={0}
              onChange={handleChange}
            />
          )}
        </Field>
        <span className={styles.space} />
        <div
          className={cx(styles.inputWrapper, styles.xsmall, styles.t45, styles.collapseNoteMobile)}
        >
          <Field name="legalRepFirstName">
            {({ field }) => (
              <FormElement
                type="string"
                name="legalRepFirstName"
                error={get(errors, 'legalRepFirstName')}
                touched={get(touched, 'legalRepFirstName')}
                label={input.legalRepFirstName.fieldTitle}
                placeholder={input.legalRepFirstName.fieldPlaceholderContent}
                note="ㅤㅤㅤㅤㅤㅤ"
                className={styles.inputEl}
                additionalCl={styles.firstNameNote}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        </div>
        <div className={cx(styles.inputWrapper, styles.xsmall, styles.t45)}>
          <Field name="legalRepLastName">
            {({ field }) => (
              <FormElement
                type="string"
                name="legalRepLastName"
                error={get(errors, 'legalRepLastName')}
                touched={get(touched, 'legalRepLastName')}
                label={input.legalRepLastName.fieldTitle}
                placeholder={input.legalRepLastName.fieldPlaceholderContent}
                note={input.legalRepLastName.fieldNote}
                className={styles.inputEl}
                additionalCl={styles.lastNameNote}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        </div>
      </div>

      <Divider className={styles.divider} />

      <div className={styles.subsection}>
        <div className={cx(styles.inputWrapper, styles.medium)}>
          <Field name="legalEntity">
            {({ field }) => (
              <FormElement
                type="select"
                name="legalEntity"
                id="legalEntity"
                options={input.legalEntityList}
                error={get(errors, 'legalEntity')}
                touched={get(touched, 'legalEntity')}
                tooltipPopup={input.legalEntity.fieldToolTip}
                label={input.legalEntity.fieldTitle}
                placeholder={input.legalEntity.fieldPlaceholderContent}
                note={input.legalEntity.fieldNote}
                className={cx(styles.inputEl, styles.medium)}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        </div>
        <div className={cx(styles.inputWrapper, styles.medium)}>
          <Field name="salesTaxIdNumber">
            {({ field }) => (
              <FormElement
                type="string"
                name="salesTaxIdNumber"
                error={get(errors, 'salesTaxIdNumber')}
                touched={get(touched, 'salesTaxIdNumber')}
                label={input.salesTaxIdNumber.fieldTitle}
                tooltipPopup={input.salesTaxIdNumber.fieldToolTip}
                placeholder={input.salesTaxIdNumber.fieldPlaceholderContent}
                note={input.salesTaxIdNumber.fieldNote}
                className={styles.inputEl}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        </div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.subsection}>
        <div className={cx(styles.inputWrapper, styles.medium)}>
          <Field name="issuingAuthority">
            {({ field }) => (
              <FormElement
                type="string"
                name="issuingAuthority"
                error={get(errors, 'issuingAuthority')}
                touched={get(touched, 'issuingAuthority')}
                label={input.issuingAuthority.fieldTitle}
                tooltipPopup={input.issuingAuthority.fieldToolTip}
                placeholder={input.issuingAuthority.fieldPlaceholderContent}
                note={input.issuingAuthority.fieldNote}
                className={styles.inputEl}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        </div>

        <div className={cx(styles.inputWrapper, styles.medium)}>
          <Field name="firmRegistrationNumber">
            {({ field }) => (
              <FormElement
                type="string"
                name="firmRegistrationNumber"
                error={get(errors, 'firmRegistrationNumber')}
                touched={get(touched, 'firmRegistrationNumber')}
                label={input.firmRegistrationNumber.fieldTitle}
                tooltipPopup={input.firmRegistrationNumber.fieldToolTip}
                placeholder={input.firmRegistrationNumber.fieldPlaceholderContent}
                note={input.firmRegistrationNumber.fieldNote}
                className={styles.inputEl}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        </div>
      </div>

      <Divider className={styles.divider} />

      <div className={styles.subsectionTitleContainer}>
        <h3 className={cx(styles.h3)}>{billingTitle}</h3>
      </div>

      <div className={styles.subsection}>
        <div className={cx(styles.inputWrapper, styles.medium)}>
          <Field name="billingIban">
            {({ field }) => (
              <FormElement
                type="string"
                name="billingIban"
                error={get(errors, 'billingIban')}
                touched={get(touched, 'billingIban')}
                label={input.billingIban.fieldTitle}
                tooltipPopup={input.billingIban.fieldToolTip}
                placeholder={input.billingIban.fieldPlaceholderContent}
                note={input.billingIban.fieldNote}
                className={cx(styles.inputEl, styles.medium)}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        </div>
        <div className={cx(styles.inputWrapper, styles.medium)}>
          <Field name="billingEmail">
            {({ field }) => (
              <FormElement
                type="string"
                name="billingEmail"
                error={get(errors, 'billingEmail')}
                touched={get(touched, 'billingEmail')}
                label={input.billingEmail.fieldTitle}
                tooltipPopup={input.billingEmail.fieldToolTip}
                placeholder={input.billingEmail.fieldPlaceholderContent}
                note={input.billingEmail.fieldNote}
                className={styles.inputEl}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        </div>
      </div>

      <Divider className={styles.divider} />

      <div className={styles.subsectionTitleContainer}>
        <h3 className={cx(styles.h3)}>{firmAddressTitle}</h3>
        <TooltipPopup content={firmAddressTitleToolTip} />
      </div>

      <div className={styles.subsection}>
        <Field name="firmAddressStreet">
          {({ field }) => (
            <FormElement
              type="string"
              name="firmAddressStreet"
              error={get(errors, 'firmAddressStreet')}
              touched={get(touched, 'firmAddressStreet')}
              label={input.firmAddressStreet.fieldTitle}
              placeholder={input.firmAddressStreet.fieldPlaceholderContent}
              note={input.firmAddressStreet.fieldNote}
              className={cx(styles.inputEl, styles.half)}
              value={field.value}
              tabIndex={0}
              onChange={handleChange}
            />
          )}
        </Field>

        <Field name="firmAddressHouse">
          {({ field }) => (
            <FormElement
              type="string"
              name="firmAddressHouse"
              error={get(errors, 'firmAddressHouse')}
              touched={get(touched, 'firmAddressHouse')}
              label={input.firmAddressHouse.fieldTitle}
              placeholder={input.firmAddressHouse.fieldPlaceholderContent}
              note={input.firmAddressHouse.fieldNote}
              className={cx(styles.inputEl, styles.xxsmall)}
              value={field.value}
              tabIndex={0}
              onChange={handleChange}
            />
          )}
        </Field>

        <span className={styles.space} />

        <Field name="firmAddressPlz">
          {({ field }) => (
            <FormElement
              type="string"
              name="firmAddressPlz"
              error={get(errors, 'firmAddressPlz')}
              touched={get(touched, 'firmAddressPlz')}
              label={input.firmAddressPlz.fieldTitle}
              placeholder={input.firmAddressPlz.fieldPlaceholderContent}
              note={input.firmAddressPlz.fieldNote}
              className={cx(styles.inputEl, styles.xxsmall)}
              value={field.value}
              tabIndex={0}
              onChange={handleChange}
            />
          )}
        </Field>

        <Field name="firmAddressCity">
          {({ field }) => (
            <FormElement
              type="string"
              name="firmAddressCity"
              error={get(errors, 'firmAddressCity')}
              touched={get(touched, 'firmAddressCity')}
              label={input.firmAddressCity.fieldTitle}
              placeholder={input.firmAddressCity.fieldPlaceholderContent}
              note={input.firmAddressCity.fieldNote}
              className={cx(styles.inputEl, styles.medium)}
              value={field.value}
              tabIndex={0}
              onChange={handleChange}
            />
          )}
        </Field>
      </div>

      <Divider className={styles.divider} />

      <div className={styles.subsection}>
        <Field name="firmTelephoneNumber">
          {({ field }) => (
            <FormElement
              type="string"
              name="firmTelephoneNumber"
              error={get(errors, 'firmTelephoneNumber')}
              touched={get(touched, 'firmTelephoneNumber')}
              label={input.firmTelephoneNumber.fieldTitle}
              placeholder={input.firmTelephoneNumber.fieldPlaceholderContent}
              note={input.firmTelephoneNumber.fieldNote}
              className={cx(styles.inputEl, styles.xsmall)}
              value={field.value}
              tabIndex={0}
              onChange={handleChange}
            />
          )}
        </Field>
      </div>

      <Divider className={styles.divider} />

      <div className={styles.subsection}>
        <div className={cx(styles.inputWrapper, styles.xsmall)}>
          <Field name="firmEmailId">
            {({ field }) => (
              <FormElement
                type="string"
                name="firmEmailId"
                error={get(errors, 'firmEmailId')}
                touched={get(touched, 'firmEmailId')}
                label={(firmEmailId as IRegisterField).fieldTitle}
                placeholder={(firmEmailId as IRegisterField).fieldPlaceholderContent}
                note={(firmEmailId as IRegisterField).fieldNote}
                className={styles.inputEl}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        </div>

        <div className={cx(styles.inputWrapper, styles.xsmall, styles.collapseNoteMobile)}>
          <Field name="firmEmailIdRepeat">
            {({ field }) => (
              <FormElement
                type="string"
                name="firmEmailIdRepeat"
                error={emailHasError}
                touched={get(touched, 'firmEmailIdRepeat')}
                label={firmEmailIdRepeat.fieldTitle}
                placeholder={firmEmailIdRepeat.fieldPlaceholderContent}
                note="ㅤㅤㅤㅤㅤㅤ"
                className={styles.inputEl}
                onPaste={preventDefaultAction}
                withValidIcon={emailConfirmed}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
        </div>
      </div>

      <Divider className={styles.divider} />
    </div>
  )
}

export default CompanyData
