import cx from 'classnames'
import { Field, FormikProps } from 'formik'
import get from 'lodash/get'
import React from 'react'

import { InputType, FormElement, IFormValue } from '@alteos/ui'

import { IGetOnSubmitFormFnValues } from '../..'
import { IRegisterField } from '../../../../types/registerForm'
import handleFormikFieldChange from '../../../../utils/handleFormikFieldChange'

import * as styles from './sourceContent.module.scss'

interface ISourceContent {
  mainTitle: string
  sourceList: ISourceList
  sourceSelection: IRegisterField
  sourceReferral: IRegisterField
  setFocusedField: (field: string) => void
}

interface ISourceList {
  key: string
  value: string
}

const SourceContent = ({
  setFocusedField,
  errors,
  touched,
  mainTitle = '',
  ...input
}: ISourceContent & FormikProps<IGetOnSubmitFormFnValues>): JSX.Element => {
  const handleChange = (
    option: string | { target: { name: string; value: IFormValue } },
    fieldName: string
  ) => {
    const { setFieldTouched, setFieldValue } = input
    setFocusedField(option?.target?.name)
    handleFormikFieldChange(option, fieldName, setFieldValue, setFieldTouched)
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>
        <h1 className={styles.h2}>{mainTitle}</h1>
      </span>

      <div className={styles.subsection}>
        <Field name="source">
          {({ field }) => (
            <FormElement
              type={InputType.select}
              name="source"
              id="source"
              options={input.sourceList}
              error={get(errors, 'source')}
              touched={get(touched, 'source')}
              className={cx(styles.inputEl, styles.large)}
              placeholder={input.sourceSelection.fieldPlaceholderContent}
              isSearchable={false}
              value={field.value}
              tabIndex={0}
              onChange={handleChange}
            />
          )}
        </Field>
      </div>
      {input.values.source === 'shoppartner_werben_shoppartner' && (
        <>
          <span className={styles.title}>
            <h1 className={styles.h2}>{input.sourceReferral.fieldTitle}</h1>
          </span>

          <div className={styles.subsection}>
            <Field name="sourceReferral">
              {({ field }) => (
                <FormElement
                  type="string"
                  name="sourceReferral"
                  error={get(errors, 'sourceReferral')}
                  touched={get(touched, 'sourceReferral')}
                  tooltipPopup={input.sourceReferral.fieldToolTip}
                  placeholder={input.sourceReferral.fieldPlaceholderContent}
                  className={cx(styles.inputEl, styles.large, styles.t75)}
                  value={field.value}
                  tabIndex={0}
                  onChange={handleChange}
                />
              )}
            </Field>
          </div>
        </>
      )}
    </div>
  )
}

export default SourceContent
