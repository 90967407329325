import { CountryCodes } from '../dictionaries'

export interface IReferralPartnerProduct {
  id: string
  displayName: {
    partner: ILocalizedString
    alteos: ILocalizedString
  }
  isUsedBy: UsedBy[] | null
  country: CountryCodes[] | null
  extensions: IExtension[] | null
}

export interface IExtension {
  name: string
  label: ILocalizedString
  type: ExtensionType
  isUsedBy?: UsedBy[]
  values?: {
    key: string | number | boolean
    label: ILocalizedString
  }[]
  required?: boolean
}

export interface ILocalizedString {
  en: string
  de: string
}

export enum UsedBy {
  Partner = 'partner',
  Alteos = 'alteos',
}

export enum ExtensionType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
}
