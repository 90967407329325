import cx from 'classnames'
import { Field, FormikProps } from 'formik'
import get from 'lodash/get'
import React, { useEffect } from 'react'

import { FormElement, Divider, InputType, IFormValue } from '@alteos/ui'

import { IProductSelectionOptionsEntity, IRegisterField } from '../../../../types/registerForm'
import handleFormikFieldChange from '../../../../utils/handleFormikFieldChange'
import { IGetOnSubmitFormFnValues } from '../../registerForm'

import * as styles from './moreInformation.module.scss'

interface IMoreInformation {
  updateFormikSchemaOptions?: (options: { [id: string]: any }) => void
  setFocusedField: (field: string) => void
  desiredURL: IRegisterField
  productSelection: IRegisterField
  productSelectionOptions?: IProductSelectionOptionsEntity[] | null
  mainTitle?: string
}

function isConfigOptionHidden(
  productSelection: { [id: string]: boolean },
  productId: string
): boolean {
  if (productSelection === null || typeof productSelection === 'undefined') {
    return true
  }

  return productSelection?.[productId] === false
}

const MoreInformation = ({
  errors,
  touched,
  mainTitle = '',
  updateFormikSchemaOptions = () => {},
  setFocusedField,
  ...input
}: IMoreInformation & FormikProps<IGetOnSubmitFormFnValues>): JSX.Element => {
  const handleChange = async (
    option: { target: { name: string; value: IFormValue } },
    fieldName: string
  ) => {
    setFocusedField(option?.target?.name)
    const { setFieldTouched, setFieldValue } = input
    handleFormikFieldChange(option, fieldName, setFieldValue, setFieldTouched)
  }

  useEffect(() => {
    updateFormikSchemaOptions(input.values)
  }, [input.values])

  const createProductCheckbox = () => {
    const checkboxes = input.productSelectionOptions.map((item) => {
      const isHidden = isConfigOptionHidden(input.values.productSelection, item.key)
      return (
        <div className={styles.divider} key={`productSelection.${item.key}`}>
          <Field
            name={`productSelection.${item.key}`}
            key={`productSelection.${item.key}-checkbox`}
          >
            {({ field }) => (
              <FormElement
                type={InputType.checkbox}
                name={`productSelection.${item.key}`}
                className={styles.checkboxContainer}
                content={item.value}
                touched={get(touched, 'productSelection')}
                value={field.value}
                tabIndex={0}
                onChange={handleChange}
              />
            )}
          </Field>
          {item.hasConfigOption === true &&
            item.configOption.map(
              (option) =>
                !isHidden && (
                  <div
                    className={styles.subsection}
                    data-test={`productSelection.${item.key}.configOption`}
                    key={`${item.key}`}
                  >
                    <span
                      className={styles.label}
                      data-test={`productSelection.${item.key}.${option.name}.label`}
                    >
                      {option.label}
                    </span>
                    <Field
                      name={`productConfigExtension.${item.key}.values.${option.name}`}
                      key={`productSelection.${item.key}.${option.name}`}
                    >
                      {({ field }) => {
                        return (
                          <FormElement
                            type={InputType.select}
                            disabled={isHidden}
                            name={`productConfigExtension.${item.key}.values.${option.name}`}
                            id={option.id}
                            options={option.options}
                            error={get(
                              errors,
                              `productConfigExtension.${item.key}.values.${option.name}`
                            )}
                            touched={Boolean(get(touched, `productConfigExtension.${item.key}`))}
                            className={cx(styles.inputEl, styles.medium)}
                            isSearchable={false}
                            value={field.value}
                            tabIndex={0}
                            onChange={handleChange}
                          />
                        )
                      }}
                    </Field>
                  </div>
                )
            )}
        </div>
      )
    })
    return checkboxes
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>
        <h1 className={styles.h2}>{mainTitle}</h1>
      </span>
      <div
        className={styles.subsection}
        data-test={`input-wrapper-product-selection${styles.label}`}
      >
        {createProductCheckbox()}
        <p className={styles.error} data-test={'input-wrapper-product-selection-error'}>
          {get(errors as object, 'productSelection')}
        </p>
      </div>
      <div className={styles.subsection}>
        <Field name="desiredURL">
          {({ field }) => (
            <FormElement
              type={InputType.string}
              name="desiredURL"
              error={get(errors, 'desiredURL')}
              touched={get(touched, 'desiredURL')}
              label={input.desiredURL.fieldTitle}
              placeholder={input.desiredURL.fieldPlaceholderContent}
              note={input.desiredURL.fieldNote}
              extensionText=".alteos.com"
              tooltipPopup={input.desiredURL.fieldToolTip}
              className={cx(styles.inputEl, styles.desired, styles.medium)}
              value={field.value}
              tabIndex={0}
              onChange={handleChange}
            />
          )}
        </Field>
      </div>
      <Divider className={styles.divider} />
    </div>
  )
}

export default MoreInformation
