import CountryCodes from './countryCodes'

const commonRegExp = {
  numbers: /^\d+$/,
  vin: /^[0-9A-Z]{6,17}$/,
  time: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
  email:
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  subdomain: {
    regex: /^[a-z0-9](?:-?[a-z0-9]+)*$/,
  },
}

export default {
  [CountryCodes.AT]: {
    ...commonRegExp,
    phone: {
      regex: /^(?:\+43|0)\d{1,4}[/\s.-]?\d{1,4}[/\s.-]?\d{3,7}$/,
      minLength: 6,
      maxLength: 50,
    },
    taxId: {
      regex: /^(ATU\d{8}|\d{2}-?\d{3}\/?\d{4})$/,
    },
    iban: {
      regex: /^AT\d{18}$/,
    },
    plz: {
      regex: /^[1-9]\d{3}$/,
    },
  },
  [CountryCodes.DE]: {
    ...commonRegExp,
    phone: {
      regex: /^(?:\+49|0)[1-9]\d{5,49}$/,
      minLength: 6,
      maxLength: 50,
    },
    taxId: {
      regex: /^((DE)?[0-9]{9})|([0-9]{5}\/[0-9]{5})|([0-9]{2,3}\/[0-9]{3,4}\/[0-9]{3,5})$/,
    },
    iban: {
      regex: /^DE\d{20}$/,
    },
    plz: {
      regex: /^\d{5}$/,
    },
  },
}
